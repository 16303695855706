import axios from 'axios';

const groupByCountry = (data) => {
  const grouped = data.reduce((acc, city) => {
    const { country, Province, Longitude, Latitude } = city;
    if (!acc[country]) {
      acc[country] = [];
    }
    acc[country].push({
      label: Province+", "+country,
      value: { Longitude, Latitude, Province },
    });
    return acc;
  }, {});

  return Object.keys(grouped).map((country) => ({
    label: country,
    options: grouped[country],
  }));
};

const fetchData = async () => {
  try {
    const response = await axios.get(
      `https://p1l4gt4ufg.execute-api.ap-southeast-1.amazonaws.com/v2/shop/location/all`,
      { headers:  {
        'Accept': 'application/json',
      }}
    );
    const groupdata = groupByCountry(response.data);
    console.log(groupdata);
    return groupdata;
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default fetchData;
